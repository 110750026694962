import React, { useState } from 'react';
import { DeviceEntity, EnvEnum } from '../../../state/devices/types';
import { IconSwitch } from '../../../aurora/icons';
import { Button } from '../../../aurora/components/Button/Button';
import styles from './DeviceSwitchModal.module.scss';
import Select from '../../../aurora/components/Select/Select';
import { showToast } from '../../../aurora/components/Toast/Toast';

interface Props {
  devices: DeviceEntity[];
  onSubmit: (targetEnv: EnvEnum) => Promise<void>;
  onCancel: () => void;
}

const DeviceSwitchModal = ({ devices, onSubmit, onCancel }: Props) => {
  const [targetEnv, setTargetEnv] = useState<EnvEnum | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (targetEnv === null) {
        throw new Error('Invalid Environment');
      }
      await onSubmit(targetEnv);
      showToast({
        type: 'success',
        title: 'Success!',
        text: 'Device environment switch successfully submitted.',
      });
      onCancel();
    } catch (err: any) {
      showToast({
        type: 'error',
        title: 'Error',
        text: 'Environment Switch failed.  Please try again.',
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  const capitalizeEnv = (str: EnvEnum) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  const listItems = devices.map((device) => (
    <li key={device._id}>
      Shipper SN: {device.serialNumber} from {capitalizeEnv(device.environment)}
    </li>
  ));

  const options = Object.values(EnvEnum)
    .filter((env) => env !== EnvEnum.DEVELOPMENT)
    .map((env) => ({
      value: env,
      label: capitalizeEnv(env),
    }));

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.iconWrapper}>
          <IconSwitch width={24} height={24} color={'#222530'} />
        </div>
        <div className={styles.info}>
          <span className={styles.title}>
            {`You're about to swap ${
              devices.length > 1 ? 'environments' : 'this environment'
            }`}
          </span>
          <p>
            {`Are you sure you want to move the following shippers to the selected environment?`}
          </p>
          <Select
            placeholder="Select Environment"
            onChange={(option) => setTargetEnv(option.value as EnvEnum)}
            variant="form"
            managedValue={targetEnv}
            options={options}
          />
          <ul className={styles.list}>{listItems}</ul>
        </div>
      </div>
      <div className={styles.buttonFooter}>
        <Button variant="secondary" label="Cancel" onClick={onCancel} />
        <Button
          variant="primary"
          label="Continue"
          onClick={handleSubmit}
          disabled={isSubmitting === true || targetEnv === null}
        />
      </div>
    </div>
  );
};

export default DeviceSwitchModal;

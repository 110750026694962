import React from 'react';
import { DeviceEntity } from '../../../state/devices/types';
import styles from './DevicesTableAction.module.scss';
import { LoadDataAction } from '../../../state/types';
import DeviceEnvSwitchButton from './DeviceEnvSwitchButton';
import EditDeviceButton from './EditDeviceButton';

interface Props {
  device: DeviceEntity;
  loadDevices: LoadDataAction<DeviceEntity>;
  showEnvironments: boolean;
}

const DevicesTableAction = ({
  device,
  loadDevices,
  showEnvironments,
}: Props) => {
  return (
    <div className={styles.actionColumn}>
      {showEnvironments && device.companyName === 'Artyc' && (
        <DeviceEnvSwitchButton devices={[device]} loadDevices={loadDevices} />
      )}
      {device.isInCurrentEnv && (
        <EditDeviceButton device={device} loadDevices={loadDevices} />
      )}
    </div>
  );
};

export default DevicesTableAction;

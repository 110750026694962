import React, { useState, useRef, useEffect } from 'react';
import { isArtyc, selectAuth } from '../../../state/auth';
import { useSelector } from 'react-redux';
import { CompanyEntity } from '../../../state/companies/types';
import { RootState } from '../../../state/store';
import { DeviceEntity, DeviceType } from '../../../state/devices/types';
import { showToast } from '../../../aurora/components/Toast/Toast';
import { Button } from '../../../aurora/components/Button/Button';
import styles from './DeviceForm.module.scss';
import Select from '../../../aurora/components/Select/Select';
import TextInput from '../../../aurora/components/TextInput/TextInput';
import NoticeModal from '../../../components/NoticeModal/NoticeModal';

interface Props {
  forDevice?: DeviceEntity;
  // TODO: make it not "any"
  onSubmit: (data: any) => Promise<any>;
  onClose: () => void;
  onDelete?: () => Promise<void>;
  successMessage: string;
  errorMessage: string;
  submitText: string;
}
const DeviceForm = ({
  forDevice,
  onSubmit,
  onClose,
  onDelete,
  successMessage,
  errorMessage,
  submitText,
}: Props) => {
  const auth = useSelector(selectAuth);

  const companies = useSelector((state: RootState) => state.companies.data);
  const [companyList, setCompanyList] = useState<CompanyEntity[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [deviceType, setDeviceType] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [pcbNumber, setPcbNumber] = useState('');
  const [password, setPassword] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [macAddress, setMacAddress] = useState('');
  const [boardVersion, setBoardVersion] = useState('');

  const editMode = forDevice !== undefined;

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  // set form focus
  const deviceRef = useRef<HTMLSelectElement>(null);
  useEffect(() => {
    deviceRef.current?.focus();
  }, []);

  // get company list
  useEffect(() => {
    if (isArtyc(auth)) {
      setCompanyList(companies);
    }
    setCompanyId(auth?.companyId || '');
  }, []);

  useEffect(() => {
    if (forDevice) {
      setDeviceType(forDevice.deviceType);
      setSerialNumber(forDevice.serialNumber);
      setCompanyId(forDevice.companyId);
      setPcbNumber(forDevice.pcbNumber);
      setMacAddress(forDevice.macAddress);
      setBoardVersion(forDevice.boardVersion);
    }
  }, [forDevice]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await onSubmit({
        companyId,
        ...(editMode
          ? {}
          : {
              boardVersion: boardVersion.trim(),
              macAddress: macAddress.trim(),
              pcbNumber: pcbNumber.trim(),
              password: password.trim(),
              deviceType,
              serialNumber: serialNumber.trim(),
            }),
      });

      onClose();
      showToast({
        type: 'success',
        title: 'Success!',
        text: successMessage,
      });
    } catch (err: any) {
      console.log(err.response.data);
      const message = err.response?.data;
      if (message !== undefined) {
        showToast({
          type: 'error',
          title: 'Error',
          text: message,
        });
      } else {
        showToast({
          type: 'error',
          title: 'Error',
          text: errorMessage,
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async () => {
    setShowConfirmDelete(false);
    setIsSubmitting(true);

    try {
      if (onDelete !== undefined) {
        await onDelete();

        onClose();
        showToast({
          type: 'success',
          title: 'Success!',
          text: 'Successfully deleted device',
        });
      }
    } catch (err: any) {
      showToast({
        type: 'error',
        title: 'Error',
        text: 'Error deleting device. Please try again or contact support',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const companyOptions = companyList.map((company) => ({
    value: company._id,
    label: company.companyName,
  }));
  const deviceOptions = Object.values(DeviceType).map((type) => ({
    value: type,
    label: type,
  }));
  const formFilled =
    (editMode ||
      (deviceType !== '' &&
        password !== '' &&
        serialNumber !== '' &&
        boardVersion !== '' &&
        macAddress !== '')) &&
    companyId !== '' &&
    pcbNumber !== '';

  return (
    <form className={styles.form}>
      <div className={styles.inputRow}>
        <Select
          label="Device Type"
          placeholder="Select Device Type"
          options={deviceOptions}
          onChange={(option) => setDeviceType(option.value)}
          variant="form"
          managedValue={deviceType}
          disabled={editMode}
        />
      </div>
      <div className={styles.inputRow}>
        <TextInput
          label="Serial Number"
          onChange={setSerialNumber}
          variant="form"
          managedText={serialNumber}
          disabled={editMode}
        />
      </div>
      <div className={styles.inputRow}>
        <TextInput
          label="PCB Number"
          onChange={setPcbNumber}
          variant="form"
          managedText={pcbNumber}
          disabled={editMode}
        />
      </div>
      <div className={styles.inputRow}>
        <TextInput
          label="MAC Address"
          onChange={setMacAddress}
          variant="form"
          managedText={macAddress}
          disabled={editMode}
        />
      </div>
      <div className={styles.inputRow}>
        <TextInput
          label="Board Version"
          onChange={setBoardVersion}
          variant="form"
          managedText={boardVersion}
          disabled={editMode}
        />
      </div>
      {editMode ? null : (
        <div className={styles.inputRow}>
          <TextInput
            label="Password"
            onChange={setPassword}
            variant="form"
            managedText={password}
          />
        </div>
      )}
      <div className={styles.inputRow}>
        <Select
          label="Company"
          placeholder="Select Company"
          options={companyOptions}
          onChange={(option) => setCompanyId(option.value)}
          variant="form"
          managedValue={companyId}
        />
      </div>

      <div className={styles.buttonContainer}>
        {onDelete !== undefined ? (
          <>
            <Button
              variant="secondaryError"
              label="Delete"
              disabled={isSubmitting}
              onClick={() => setShowConfirmDelete(true)}
            />
            <NoticeModal
              title="Are you sure?"
              notice="This action cannot be undone."
              show={showConfirmDelete}
              onClose={() => setShowConfirmDelete(false)}
              buttons={
                <>
                  <Button
                    variant="secondary"
                    label="Cancel"
                    onClick={() => setShowConfirmDelete(false)}
                  />
                  <Button
                    variant="secondaryError"
                    label="Delete"
                    onClick={handleDelete}
                  />
                </>
              }
            />
          </>
        ) : (
          <Button
            variant="secondary"
            disabled={isSubmitting}
            label="Cancel"
            onClick={onClose}
          />
        )}
        <Button
          variant="primary"
          label={submitText}
          disabled={isSubmitting || !formFilled}
          onClick={handleSubmit}
        />
      </div>
    </form>
  );
};

export default DeviceForm;

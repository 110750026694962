import React, { useState } from 'react';
import FileUpload, {
  FileWithPreview,
} from '../../../../aurora/components/FileUpload/FileUpload';
import { Text } from '../../../../aurora/typography/Text/Text';
import DataModal from '../../../../components/DataModal';
import styles from './TemperatureOverlayModal.module.scss';
import Papa from 'papaparse';
import { Button } from '../../../../aurora/components/Button/Button';
import Checkbox from '../../../../aurora/components/Checkbox/Checkbox';
import NumberInput from '../../../../aurora/components/NumberInput/NumberInput';
import { ExternalTempData, ExternalTempDatapoint } from './SnapshotsGraph';

interface Props {
  showOverlay: boolean;
  setShowOverlay: (show: boolean) => void;
  setExternalTempData: (tempData: ExternalTempData[]) => void;
}
const TemperatureOverlayModal = ({
  showOverlay,
  setShowOverlay,
  setExternalTempData,
}: Props) => {
  const [fields, setFields] = useState<string[]>([]);
  const [csvData, setCsvData] = useState<Record<string, string>[]>([]);
  const [offsetMinutes, setOffsetMinutes] = useState<number | null>(null);
  const [selectedX, setSelectedX] = useState<string | null>(null);
  const [selectedYs, setSelectedYs] = useState<Record<string, boolean>>({});
  const [error, setError] = useState<string | null>(null);

  const onClearSelected = () => {
    setSelectedX(null);
    setSelectedYs({});
    setOffsetMinutes(null);
    setExternalTempData([]);
    setError(null);
  };

  const onSetFiles = (files: FileWithPreview[]) => {
    const file = files[0];
    if (file === undefined) {
      setFields([]);
      setCsvData([]);
      onClearSelected();
      return;
    }

    Papa.parse(file, {
      header: true,
      complete: (result) => {
        setFields(result.meta.fields || []);
        setCsvData(result.data as Record<string, string>[]);
        onClearSelected();
      },
      error: (error) => {
        setError(`Error parsing CSV: ${error}`);
      },
    });
  };

  const onSubmit = () => {
    const selectedTempFields = Object.keys(selectedYs).filter(
      (key) => selectedYs[key]
    );

    try {
      const invalidRows: string[] = [];

      // convert CSV to format for graph if all data is valid
      const tempData: ExternalTempData[] = selectedTempFields.map((field) => {
        const datapoints: ExternalTempDatapoint[] = [];
        csvData.forEach((row, i) => {
          if (row[field] === undefined || row[field] === '') {
            return;
          }

          const tempC = parseFloat(row[field]);
          const timestamp = Date.parse(row[selectedX!]);
          const validDate = !isNaN(timestamp);

          if (!validDate) {
            // first row is headers
            invalidRows.push(`timestamp ${i + 2}`);
          } else if (isNaN(tempC)) {
            invalidRows.push(`${field} ${i + 2}`);
          } else {
            datapoints.push({
              tempC,
              timestamp: new Date(timestamp).valueOf(),
            });
          }
        });

        return {
          label: field,
          offsetMinutes: offsetMinutes!,
          datapoints,
        };
      });

      setExternalTempData(tempData);
      if (invalidRows.length === 0) {
        setShowOverlay(false);
      } else {
        setError(
          `Your CSV contains invalid data. Those rows have been skipped and the data has been rendered. Close the modal to continue. Or fix the rows and upload again. Affected rows: ${invalidRows.join(
            ','
          )}`
        );
      }
    } catch (err) {
      setError(`Error submitting data: ${err}`);
    }
  };

  const formFilled =
    selectedX !== null &&
    Object.values(selectedYs).filter((value) => value).length > 0 &&
    offsetMinutes !== null &&
    error === null;

  const fieldSelectForm =
    fields.length > 0 ? (
      <>
        <div className={styles.fieldsContainer}>
          <div>
            <Text size="s">Select timestamp column</Text>
            {fields.map((field) => (
              <Checkbox
                key={`x-${field}`}
                managedChecked={field === selectedX}
                onChange={(isChecked) => {
                  if (isChecked) {
                    setSelectedX(field);
                    setError(null);
                  } else {
                    setSelectedX(null);
                    setError(null);
                  }
                }}
                label={field}
              />
            ))}
          </div>
          <div>
            <Text size="s">Select temperature columns</Text>
            {fields.map((field) => (
              <Checkbox
                key={`x-${field}`}
                onChange={(isChecked) => {
                  setSelectedYs({ ...selectedYs, [field]: isChecked });
                  setError(null);
                }}
                label={field}
                managedChecked={selectedYs[field] === true}
              />
            ))}
          </div>
        </div>
        <NumberInput
          label="Timestamp offset in minutes. Zero for no offset. Positive number for minutes ahead of our graph. Negative for minutes behind."
          onChange={(num) => {
            setOffsetMinutes(num);
            setError(null);
          }}
          managedNum={offsetMinutes}
        />
        <div className={styles.buttons}>
          <Button
            variant="primary"
            label="Submit"
            disabled={!formFilled}
            onClick={onSubmit}
          />
          <Button variant="secondary" label="Clear" onClick={onClearSelected} />
        </div>
      </>
    ) : null;

  return (
    <DataModal
      title={'Overlay External Temperatures'}
      show={showOverlay}
      onClose={() => setShowOverlay(false)}
    >
      <div className={styles.container}>
        <div className={styles.instructions}>
          <Text size="s">
            Upload a CSV to overlay external temperature data onto the shipment
            graph.
          </Text>
          <Text size="s">
            The first row must be the headers. Every other row must be data.
            Temperature data must be in °C.
          </Text>
          <Text size="s">
            Don't worry about the timestamp's timezone &mdash; we match up the
            first timestamp with the start of our graph, +/- an offset that you
            set.
          </Text>
        </div>
        <FileUpload
          label="Upload CSV"
          fileType="text/csv"
          maxFiles={1}
          onSetFiles={onSetFiles}
        />
        {fieldSelectForm}
        {error !== null ? <div className={styles.error}>{error}</div> : null}
      </div>
    </DataModal>
  );
};

export default TemperatureOverlayModal;

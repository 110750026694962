import React, { useEffect, useState } from 'react';
import { ShipmentEntity } from '../../../../state/shipments/types';
import ShipmentsApi from '../../../../api/shipmentsApi';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useDispatch, useSelector } from 'react-redux';
import { isArtyc, selectAuth } from '../../../../state/auth';
import SnapshotsGraph, { ExternalTempData } from './SnapshotsGraph';
import {
  ButtonGroup,
  ButtonProps,
} from '../../../../aurora/components/ButtonGroup/ButtonGroup';
import DownloadCsvButton from '../DownloadCsvButton';
import ArtycPanel from './ArtycPanel';
import { EventEntity } from '../../../../state/events/types';
import ShipmentsGraph from './ShipmentsGraph';
import {
  selectSnapshotsByShipment,
  setSnapshots,
  ShipmentToSnapshotsMap,
} from '../../../../state/shipments';
import styles from './SnapshotsGraphContainer.module.scss';
import { RootState } from '../../../../state/store';

interface Props {
  shipments?: ShipmentEntity[];
  event?: EventEntity;
  onLoaded?: () => void;
  printVersion?: boolean;
}
const SnapshotsGraphContainer = ({
  shipments,
  event,
  onLoaded,
  printVersion,
}: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const snapshotsByShipment = useSelector(selectSnapshotsByShipment);
  const dispatch = useDispatch();
  const viewAsCustomer = useSelector(
    (state: RootState) => state.shipments.viewAsCustomer
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loadedShipments, setLoadedShipments] = useState<ShipmentEntity[]>([]);
  const [tempUnit, setTempUnit] = useState<'C' | 'F'>('C');

  const [tempThresholdsC, setTempThresholdsC] = useState<number[]>([]);
  const [minTempAxisC, setMinTempAxisC] = useState<number | undefined>(
    undefined
  );
  const [maxTempAxisC, setMaxTempAxisC] = useState<number | undefined>(
    undefined
  );
  const [showDateAsDuration, setShowDateAsDuration] = useState<boolean>(true);
  const [showLidOpen, setShowLidOpen] = useState<boolean>(false);
  const [externalTempData, setExternalTempData] = useState<ExternalTempData[]>(
    []
  );

  const isArtycUser = isArtyc(auth) && !viewAsCustomer;

  const loadShipments = async () => {
    try {
      setLoading(true);
      setError(false);

      if (shipments !== undefined) {
        setLoadedShipments(shipments);
      } else if (event !== undefined) {
        const shipmentResp = await ShipmentsApi.getShipment(
          axiosPrivate,
          auth,
          event.shipment
        );
        setLoadedShipments([shipmentResp]);
      } else {
        throw new Error('expected way to get shipment');
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const loadSnapshots = async () => {
    try {
      setLoading(true);
      setError(false);

      for (let shipment of loadedShipments) {
        if (snapshotsByShipment[shipment._id] === undefined) {
          const snapshots = await ShipmentsApi.getShipmentSnapshots(
            axiosPrivate,
            auth,
            shipment._id
          );
          dispatch(setSnapshots({ [shipment._id]: snapshots }));
        }
      }
      onLoaded && onLoaded();
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadShipments();
  }, []);

  useEffect(() => {
    if (Object.keys(loadedShipments).length > 0) {
      loadSnapshots();
    }
  }, [loadedShipments]);

  if (loading) {
    return <div className={styles.container}>Loading...</div>;
  }

  if (error || loadedShipments.length === 0) {
    return (
      <div className={styles.container}>
        Error -- please close and open again
      </div>
    );
  }

  const hasShipment =
    loadedShipments.length > 0 &&
    loadedShipments.some(
      (shipment) => snapshotsByShipment[shipment._id] !== undefined
    );

  if (!hasShipment) {
    return <div className={styles.container}>No data found</div>;
  }

  const loadedSnapshotsByShipment: ShipmentToSnapshotsMap =
    loadedShipments.reduce((acc, shipment) => {
      if (snapshotsByShipment[shipment._id]) {
        acc[shipment._id] = snapshotsByShipment[shipment._id];
      }
      return acc;
    }, {} as ShipmentToSnapshotsMap);

  const graph =
    loadedShipments.length === 1 ? (
      <SnapshotsGraph
        snapshots={loadedSnapshotsByShipment[loadedShipments[0]._id]}
        externalTempData={externalTempData}
        tempUnit={tempUnit}
        isArtyc={isArtycUser}
        tempThresholdsC={tempThresholdsC}
        minTempAxisC={minTempAxisC}
        maxTempAxisC={maxTempAxisC}
        showLidOpen={showLidOpen}
        showDateAsDuration={showDateAsDuration}
      />
    ) : (
      <ShipmentsGraph
        shipments={loadedShipments}
        snapshotsMap={loadedSnapshotsByShipment}
        tempUnit={tempUnit}
        isArtyc={isArtycUser}
        tempThresholdsC={tempThresholdsC}
        minTempAxisC={minTempAxisC}
        maxTempAxisC={maxTempAxisC}
      />
    );

  const temps: ButtonProps[] = [
    {
      label: '°C',
      onClick: () => setTempUnit('C'),
    },
    {
      label: '°F',
      onClick: () => setTempUnit('F'),
    },
  ];

  return (
    <div className={styles.container}>
      {!printVersion ? (
        <ArtycPanel
          isArtyc={isArtycUser}
          shipments={loadedShipments}
          snapshotsMap={loadedSnapshotsByShipment}
          tempThresholdsC={tempThresholdsC}
          setTempThresholdsC={setTempThresholdsC}
          setMinTempAxisC={setMinTempAxisC}
          setMaxTempAxisC={setMaxTempAxisC}
          showDateAsDuration={showDateAsDuration}
          setShowDateAsDuration={setShowDateAsDuration}
          showLidOpen={showLidOpen}
          setShowLidOpen={setShowLidOpen}
          tempUnit={tempUnit}
          setExternalTempData={setExternalTempData}
        />
      ) : null}
      <div className={styles.graphPanel}>
        {printVersion ? null : (
          <div className={styles.graphButtons}>
            <ButtonGroup buttonProps={temps} initialActiveIdx={0} />
          </div>
        )}
        {graph}
        {printVersion ? null : (
          <div className={styles.downloadButton}>
            {isArtycUser ? (
              <DownloadCsvButton
                shipments={loadedShipments}
                text="Download Full Log"
                fullLog={true}
              />
            ) : null}
            <DownloadCsvButton
              shipments={loadedShipments}
              text="Download Shipment"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SnapshotsGraphContainer;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Auth, ROLES } from './types';
import { RootState } from '../store';

export interface AuthState {
  auth: Auth | null;
}

const initialState: AuthState = {
  auth: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, { payload }: PayloadAction<Auth>) => {
      state.auth = payload;
    },
    clearAuth: (state) => {
      state.auth = null;
    },
  },
});

export const { setAuth, clearAuth } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth.auth;

export default authSlice.reducer;

export const isArtycAdmin = (auth: Auth | null): boolean =>
  auth?.role === ROLES.ARTYC_ADMIN.pid;

export const isArtyc = (auth: Auth | null): boolean =>
  auth?.role === ROLES.ARTYC_ADMIN.pid ||
  auth?.role === ROLES.ARTYC_EMPLOYEE.pid;

export const isCompanyEmployee = (auth: Auth | null): boolean =>
  auth?.role === ROLES.COMPANY_EMPLOYEE.pid;

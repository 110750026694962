import React from 'react';
import ModalAction from '../../../components/table/ModalAction';
import { DeviceEntity, EnvEnum } from '../../../state/devices/types';
import { IconSwitch } from '../../../aurora/icons';
import { isArtyc, selectAuth } from '../../../state/auth';
import { useSelector } from 'react-redux';
import { LoadDataAction } from '../../../state/types';
import DeviceSwitchModal from './DeviceSwitchModal';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import DevicesApi from '../../../api/devicesApi';

interface Props {
  devices: DeviceEntity[];
  loadDevices: LoadDataAction<DeviceEntity>;
  isLabeledButton?: boolean;
  isDisabled?: boolean;
}

const DeviceEnvSwitchButton = ({
  devices,
  loadDevices,
  isLabeledButton = false,
  isDisabled = false,
}: Props) => {
  const auth = useSelector(selectAuth);
  const axiosPrivate = useAxiosPrivate();

  if (!isArtyc(auth)) {
    return null;
  }

  const handleSubmit = async (
    environment: EnvEnum,
    onDataChange: () => Promise<void>
  ) => {
    const deviceIds = devices.map((device) => device._id);
    await DevicesApi.switchEnvs(axiosPrivate, deviceIds, environment);
    await onDataChange();
  };

  return (
    <ModalAction
      label={
        isLabeledButton ? 'Switch Shipper Environment' : 'Switch Environment'
      }
      loadDataAction={loadDevices}
      stateName="devices"
      icon={IconSwitch}
      isLabeledButton={isLabeledButton}
      isDisabled={isDisabled}
      modalContent={(onDataChange, onClose) => (
        <DeviceSwitchModal
          devices={devices}
          onSubmit={(targetEnv) => handleSubmit(targetEnv, onDataChange)}
          onCancel={onClose}
        />
      )}
    />
  );
};

export default DeviceEnvSwitchButton;

import React from 'react';
import Select from '../../../aurora/components/Select/Select';
import { useSelector } from 'react-redux';
import { isArtyc, selectAuth } from '../../../state/auth';
import { ROLES } from '../../../state/auth/types';
import styles from './RoleSelect.module.scss';
interface Props {
  onRoleSelect: (roleId: string | undefined) => void;
  selectedRole: string | undefined;
}
const RoleSelect = ({ onRoleSelect, selectedRole }: Props) => {
  const auth = useSelector(selectAuth);

  const options = Object.values(ROLES)
    // don't show artyc admin to non artyc admin or artyc employee to non artyc
    .filter((role) => {
      if (
        !isArtyc(auth) &&
        [ROLES.ARTYC_ADMIN.pid, ROLES.ARTYC_EMPLOYEE.pid].includes(role.pid)
      ) {
        return false;
      }
      return true;
    })
    .map((role) => ({
      value: role.pid,
      label: role.name,
    }));

  options.unshift({ value: '', label: 'Select Role' });

  const onChange = (newValue: string) => {
    if (newValue === '') {
      onRoleSelect(undefined);
    } else {
      onRoleSelect(newValue);
    }
  };

  return (
    <Select
      placeholder="Select Role"
      options={options}
      className={styles.roleSelect}
      onChange={(option) => onChange(option.value)}
      managedValue={selectedRole}
      variant="filter"
    />
  );
};

export default RoleSelect;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { isArtyc, selectAuth } from '../../../state/auth';
import { loadCompanies } from '../../../state/companies';
import { RootState } from '../../../state/store';
import MobileChecklistFilter from './MobileChecklistFilter';

interface CompanyFilterProps {
  setCompanyIdsAction: (companyIds: string[] | undefined) => void;
  filteredCompanyIds: string[] | undefined;
}

const MobileCompanyFilter = ({
  setCompanyIdsAction,
  filteredCompanyIds,
}: CompanyFilterProps) => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const companiesState = useSelector((state: RootState) => state.companies);
  const companies = companiesState.data;
  const isArtycUser = isArtyc(auth);

  const fetchCompanies = async () => {
    const action = await loadCompanies(axiosPrivate, auth!, companiesState);
    dispatch(action);
  };

  useEffect(() => {
    if (companies.length === 0 && isArtycUser) {
      fetchCompanies();
    }
  }, []);

  return (
    <MobileChecklistFilter
      sections={[
        {
          onApplyFilters: (companyIds) => setCompanyIdsAction(companyIds),
          onClear: () => setCompanyIdsAction(undefined),
          title: 'Companies',
          selectedIds: filteredCompanyIds || [],
          hasSearch: true,
          searchPlaceholder: 'Search by company name',
          options: companies.map((company) => ({
            id: company._id,
            label: company.companyName,
          })),
          show: isArtycUser,
        },
      ]}
    />
  );
};

export default MobileCompanyFilter;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  EventEntity,
  EventFilters,
  EventStatusesEnum,
  StatusFilter,
} from './types';
import {
  LoadDataAction,
  EntityState,
  SortPayloadAction,
  DataPayload,
} from '../types';
import { structuredFetch } from '../../api/api';
import { AxiosInstance } from 'axios';
import { Auth } from '../auth/types';
import EventsApi from '../../api/eventsApi';
import { showToast } from '../../aurora/components/Toast/Toast';

export interface EventState extends EntityState<EventEntity> {
  selectedFilters?: EventFilters;
  bannerDismissed: boolean;
  newEventCount: number;
}

const initialState: EventState = {
  data: [],
  currentPage: 0,
  resultsPerPage: 8,
  totalPages: 0,
  sortColumn: 'eventStart',
  sortDirection: -1,
  selectedFilters: { status: 'ALL' },
  bannerDismissed: false,
  newEventCount: 0,
};

interface BulkActionPayload {
  axios: AxiosInstance;
  auth: Auth | null;
  eventIds: string[];
}
export const markReviewedAction = createAsyncThunk(
  'events/markReviewed',
  ({ axios, auth, eventIds }: BulkActionPayload) => {
    return EventsApi.markReviewed(axios, auth, eventIds);
  }
);

export const downloadCsvAction = createAsyncThunk(
  'events/downloadCsv',
  ({ axios, auth, eventIds }: BulkActionPayload) => {
    return EventsApi.downloadShipmentCsv(axios, auth, eventIds);
  }
);

const eventSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setEvents: (
      state,
      { payload }: PayloadAction<DataPayload<EventEntity>>
    ) => {
      state.data = payload.entities;
      state.totalPages = payload.totalPages;
    },
    setStatusFilter: (state, { payload }: PayloadAction<StatusFilter>) => {
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        status: payload,
      });
      state.currentPage = initialState.currentPage;
    },
    setDateFilter: (
      state,
      { payload }: PayloadAction<[string | undefined, string | undefined]>
    ) => {
      const [startDate, endDate] = payload;
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        startDate,
        endDate,
      });
      state.currentPage = initialState.currentPage;
    },
    setSearchFilter: (state, { payload }: PayloadAction<string>) => {
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        search: payload === '' ? undefined : payload,
      });
      state.currentPage = initialState.currentPage;
    },
    setCompanyIdsFilter: (
      state,
      { payload }: PayloadAction<string[] | undefined>
    ) => {
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        companyIds: payload,
      });
      state.currentPage = initialState.currentPage;
    },
    sortEvents: (state, { payload }: SortPayloadAction<EventEntity>) => {
      state.sortColumn = payload.column;
      state.sortDirection = payload.direction;
    },
    setBannerDismissed: (state) => {
      state.bannerDismissed = true;
    },
    setNewEventCount: (state, { payload }: PayloadAction<number>) => {
      state.newEventCount = payload;
    },
    setCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.currentPage = payload;
    },
    resetPage: (state) => {
      state.selectedFilters = initialState.selectedFilters;
      state.sortColumn = initialState.sortColumn;
      state.sortDirection = initialState.sortDirection;
      state.currentPage = initialState.currentPage;
    },
  },
  extraReducers: (builder) => {
    // TODO(events): add loading state / error state for reviewed / download

    builder.addCase(markReviewedAction.pending, () => {
      showToast({
        type: 'loading',
        title: 'Updating',
        text: 'Updating your events. Please wait...',
      });
    });

    builder.addCase(markReviewedAction.fulfilled, (state, action) => {
      const updatedCount = action.payload.updated;
      state.newEventCount = state.newEventCount - updatedCount;

      // assume all of them will have been marked reviewed
      const updatedEvents = action.meta.arg.eventIds;
      const updatedData = state.data.map((event) => {
        if (updatedEvents.includes(event._id)) {
          return Object.assign({}, event, {
            status: EventStatusesEnum.REVIEWED,
          });
        }
        return event;
      });
      state.data = updatedData;
      showToast({
        type: 'success',
        title: 'Success!',
        text: 'Event statuses updated!',
      });
    });

    builder.addCase(markReviewedAction.rejected, () => {
      showToast({
        type: 'error',
        title: 'Un oh...',
        text: 'There was an error updating your events. \nPlease try again or contact support if it continues.',
      });
    });

    builder.addCase(downloadCsvAction.pending, () => {
      showToast({
        type: 'loading',
        title: 'Downloading',
        text: 'Preparing your files for download. Please wait...',
      });
    });

    builder.addCase(downloadCsvAction.fulfilled, (state, action) => {
      const url = window.URL.createObjectURL(new Blob([action.payload]));

      const link = document.createElement('a');
      link.href = url;
      if (action.payload.type === 'text/csv') {
        const eventId = action.meta.arg.eventIds[0];
        link.setAttribute('download', `${eventId}-event_shipment.csv`);
      } else {
        link.setAttribute('download', 'shipments.zip');
      }

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      showToast({
        type: 'success',
        title: 'Success!',
        text: 'Your files have successfully downloaded.\nPlease check your downloads folder.',
      });
    });

    builder.addCase(downloadCsvAction.rejected, () => {
      showToast({
        type: 'error',
        title: 'Uh oh...',
        text: 'There was an error preparing your files. \nPlease try again or contact support if it continues.',
      });
    });
  },
});

export const loadEvents: LoadDataAction<EventEntity> = async (
  axios: AxiosInstance,
  auth: Auth,
  state: EntityState<EventEntity>
) => {
  const {
    resultsPerPage,
    currentPage,
    sortColumn,
    sortDirection,
    selectedFilters,
  } = state;

  const { entities, totalPages } = await structuredFetch<EventEntity>(
    axios,
    auth,
    'events',
    resultsPerPage,
    currentPage,
    sortColumn,
    sortDirection,
    selectedFilters
  );

  return setEvents({ entities, totalPages });
};

export const {
  setEvents,
  setStatusFilter,
  setDateFilter,
  setCompanyIdsFilter,
  sortEvents,
  setBannerDismissed,
  setSearchFilter,
  setNewEventCount,
  setCurrentPage,
  resetPage,
} = eventSlice.actions;

export default eventSlice.reducer;

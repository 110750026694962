import React, { useState } from 'react';
import { Button } from '../../../aurora/components/Button/Button';
import { IconPlus } from '../../../aurora/icons';
import DataModal from '../../../components/DataModal';
import DeviceForm from './DeviceForm';
import { useDispatch, useSelector } from 'react-redux';
import { isArtyc, selectAuth } from '../../../state/auth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { RootState } from '../../../state/store';
import DevicesApi from '../../../api/devicesApi';
import { LoadDataAction } from '../../../state/types';
import { DeviceEntity } from '../../../state/devices/types';

interface Props {
  loadDevices: LoadDataAction<DeviceEntity>;
}

const AddDeviceButton = ({ loadDevices }: Props) => {
  const auth = useSelector(selectAuth);

  const [showModal, setShowModal] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.devices);

  if (!isArtyc(auth)) {
    return null;
  }

  const onClose = () => setShowModal(false);

  return (
    <div>
      <Button
        variant="primary"
        label="Add Device"
        iconLeft={IconPlus}
        onClick={() => setShowModal(true)}
      />
      <DataModal title={'Add Device'} show={showModal} onClose={onClose}>
        <DeviceForm
          onSubmit={async (data) => {
            await DevicesApi.createDevice(axiosPrivate, data);
            const action = await loadDevices(axiosPrivate, auth!, state);
            dispatch(action);
          }}
          successMessage="Successfully added device"
          errorMessage="Failed to add device. Please try again or contact support"
          submitText="Add Device"
          onClose={onClose}
        />
      </DataModal>
    </div>
  );
};

export default AddDeviceButton;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchFilter } from '../../../state/users';
import { isArtyc, selectAuth } from '../../../state/auth';
import TableSearch from '../../../aurora/components/TableSearch/TableSearch';

const UserSearch = () => {
  const dispatch = useDispatch();

  const auth = useSelector(selectAuth);
  const placeholder = isArtyc(auth)
    ? 'Search by name, email, company'
    : 'Search by name and email';

  return (
    <TableSearch
      placeholder={placeholder}
      onChange={(text) => {
        dispatch(setSearchFilter(text));
      }}
    />
  );
};

export default UserSearch;

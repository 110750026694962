import { AxiosInstance, AxiosResponse } from 'axios';
import { Auth } from '../state/auth/types';
import { DataPayload } from '../state/types';
import { isArtyc } from '../state/auth';
import { del, get, patch, post } from '../hooks/useAxiosPrivate';

// a fetch where it hits a different endpoint for admin vs company
export const genericFetch = async <T>(
  axios: AxiosInstance,
  auth: Auth | null,
  artycUrl: string,
  companyUrl: string,
  params?: any,
  config?: object
) => {
  const entityURL = isArtyc(auth) ? artycUrl : companyUrl;
  const response: AxiosResponse<T> = await get(
    axios,
    entityURL,
    params,
    config
  );

  return response.data;
};

// for endpoints that follow the exact same structure
export const structuredFetch = async <T>(
  axios: AxiosInstance,
  auth: Auth | null,
  entityName: string,
  resultsPerPage: number,
  currentPage: number,
  sortColumn: string,
  sortDirection: number,
  filters: any
): Promise<DataPayload<T>> => {
  const artycUrl = `/artyc/${entityName}`;
  const companyUrl = `/api/v1/company/${auth?.companyId}/${entityName}`;
  const params = {
    limit: resultsPerPage,
    page: currentPage,
    sortColumn,
    sortDirection,
    filters,
  };

  return genericFetch(axios, auth, artycUrl, companyUrl, params);
};

export const genericPost = async <T>(
  axios: AxiosInstance,
  auth: Auth | null,
  artycUrl: string,
  companyUrl: string,
  params?: any,
  config?: object
) => {
  const entityURL = isArtyc(auth) ? artycUrl : companyUrl;

  const response: AxiosResponse<T> = await post(
    axios,
    entityURL,
    params,
    config
  );

  return response.data;
};

export const genericPatch = async <T>(
  axios: AxiosInstance,
  auth: Auth | null,
  artycUrl: string,
  companyUrl: string,
  params?: any
) => {
  const entityURL = isArtyc(auth) ? artycUrl : companyUrl;

  const response: AxiosResponse<T> = await patch(axios, entityURL, params);

  return response.data;
};

export const genericDelete = async <T>(
  axios: AxiosInstance,
  auth: Auth | null,
  artycUrl: string,
  companyUrl: string
) => {
  const entityURL = isArtyc(auth) ? artycUrl : companyUrl;
  const response: AxiosResponse<T> = await del(axios, entityURL);
  return response.data;
};

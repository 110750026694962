import React, { useEffect, useState } from 'react';
import { ShipmentEntity } from '../../../../state/shipments/types';
import { formatGraphTime } from '../../../../utils/dateUtil';
import Switch from '../../../../aurora/components/Switch/Switch';
import TextInput from '../../../../aurora/components/TextInput/TextInput';
import { fToC } from '../../../../utils/tempUtil';
import { ShipmentToSnapshotsMap } from '../../../../state/shipments';
import styles from './ArtycPanel.module.scss';
import { Button } from '../../../../aurora/components/Button/Button';
import TemperatureOverlayModal from './TemperatureOverlayModal';
import { ExternalTempData } from './SnapshotsGraph';

interface Props {
  isArtyc: boolean;
  shipments: ShipmentEntity[];
  snapshotsMap: ShipmentToSnapshotsMap;
  tempThresholdsC: number[];
  setTempThresholdsC: (thresholds: number[]) => void;
  setMinTempAxisC: (temp: number | undefined) => void;
  setMaxTempAxisC: (temp: number | undefined) => void;
  showDateAsDuration: boolean;
  setShowDateAsDuration: (show: boolean) => void;
  showLidOpen: boolean;
  setShowLidOpen: (show: boolean) => void;
  tempUnit: 'C' | 'F';
  setExternalTempData: (data: ExternalTempData[]) => void;
}
const ArtycPanel = ({
  shipments,
  snapshotsMap,
  setTempThresholdsC,
  tempThresholdsC,
  setMinTempAxisC,
  setMaxTempAxisC,
  isArtyc,
  showDateAsDuration,
  setShowDateAsDuration,
  showLidOpen,
  setShowLidOpen,
  tempUnit,
  setExternalTempData,
}: Props) => {
  const firstShipment = shipments[0];
  const viewingMultiple = shipments.length > 1;
  const thresholdCenter = firstShipment.setTemperature;

  const [showOverlay, setShowOverlay] = useState(false);

  // TODO: once we have shipping profiles, change these to match the min/max temp from there
  useEffect(() => {
    setTempThresholdsC([thresholdCenter - 3, thresholdCenter + 3]);
  }, [firstShipment]);

  const toggleThresholds = () => {
    if (tempThresholdsC.length === 0) {
      setTempThresholdsC([thresholdCenter - 3, thresholdCenter + 3]);
    } else {
      setTempThresholdsC([]);
    }
  };

  const setNum =
    (func: (num: number | undefined) => void) => (text: string) => {
      if (text === '') {
        func(undefined);
      } else {
        const num = parseInt(text);
        if (!isNaN(num)) {
          if (tempUnit === 'C') {
            func(num);
          } else {
            func(fToC(num));
          }
        }
      }
    };

  const singleShipmentInfo = !viewingMultiple ? (
    <>
      <div className={styles.title}>Internal ID</div>
      <div className={styles.text}>{firstShipment._id}</div>

      <div className={styles.title}>Firmware</div>
      <div className={styles.text}>{firstShipment.firmwareVersion}</div>

      <div className={styles.title}>Snapshots</div>
      <div className={styles.text}>
        {snapshotsMap[firstShipment._id].length}
      </div>

      <div className={styles.title}>Uploaded At</div>
      <div className={styles.text}>
        {formatGraphTime(firstShipment.createdAt)}
      </div>
    </>
  ) : null;

  const thresholdSection = (
    <div className={styles.section}>
      <div className={styles.title}>Show Thresholds</div>
      <Switch
        isSwitchOn={tempThresholdsC.length !== 0}
        onChange={toggleThresholds}
      />
    </div>
  );

  const lidOpenSection = (
    <div className={styles.section}>
      <div className={styles.title}>Show Lid Opens</div>
      <Switch isSwitchOn={showLidOpen} onChange={setShowLidOpen} />
    </div>
  );

  const dateAsDurationSection = (
    <div className={styles.section}>
      <div className={styles.title}>Show Date As Duration</div>
      <Switch
        isSwitchOn={showDateAsDuration}
        onChange={setShowDateAsDuration}
      />
    </div>
  );

  const yAxisRangeSection = (
    <div className={styles.section}>
      <div className={styles.title}>Y Axis Range (°{tempUnit})</div>
      <div
        style={{
          display: 'flex',
          gap: '4px',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <TextInput
          variant="form"
          onChange={setNum(setMinTempAxisC)}
          sx={{ maxWidth: '50px' }}
        />
        <span>-</span>
        <TextInput
          variant="form"
          onChange={setNum(setMaxTempAxisC)}
          sx={{ maxWidth: '50px' }}
        />
      </div>
    </div>
  );

  const overlayTempSection = (
    <div className={styles.section}>
      <Button
        label="Overlay Temp"
        variant="secondary"
        onClick={() => setShowOverlay(true)}
      />
      <TemperatureOverlayModal
        showOverlay={showOverlay}
        setShowOverlay={setShowOverlay}
        setExternalTempData={setExternalTempData}
      />
    </div>
  );

  return (
    <div className={styles.panel}>
      {isArtyc ? singleShipmentInfo : null}
      {thresholdSection}
      {viewingMultiple ? null : lidOpenSection}
      {viewingMultiple ? null : dateAsDurationSection}
      {isArtyc ? yAxisRangeSection : null}
      {isArtyc ? overlayTempSection : null}
    </div>
  );
};

export default ArtycPanel;

import React from 'react';

import { IconBase, IconProps } from './IconBase';

export const IconSwitch: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase viewBox="0 0 14 14" {...props}>
    <path
      d="M10.2546 0.933044L9.37062 1.81693L10.8037 3.24999H7.62512V4.49998H10.8037L9.37062 5.93304L10.2546 6.81691L13.1965 3.87498L10.2546 0.933044ZM5.43759 3.87498C5.43759 3.01204 4.73804 2.31248 3.87509 2.31248C3.01215 2.31248 2.31259 3.01204 2.31259 3.87498C2.31259 4.73793 3.01215 5.43748 3.87509 5.43748C4.73804 5.43748 5.43759 4.73793 5.43759 3.87498ZM6.68762 3.87498C6.68762 5.42829 5.42839 6.68748 3.87509 6.68748C2.32179 6.68748 1.06259 5.42829 1.06259 3.87498C1.06259 2.32168 2.32179 1.06248 3.87509 1.06248C5.42839 1.06248 6.68762 2.32168 6.68762 3.87498ZM3.19647 10.75L4.62954 12.183L3.74565 13.0669L0.803711 10.125L3.74565 7.18304L4.62954 8.06691L3.19647 9.49998H6.37512V10.75H3.19647ZM8.87512 11.375H11.3751V8.87498H8.87512V11.375ZM7.62512 8.24998C7.62512 7.90479 7.90494 7.62498 8.25012 7.62498H12.0001C12.3452 7.62498 12.6251 7.90479 12.6251 8.24998V12C12.6251 12.3452 12.3452 12.625 12.0001 12.625H8.25012C7.90494 12.625 7.62512 12.3452 7.62512 12V8.24998Z"
      fill="currentColor"
    />
  </IconBase>
);

/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import { Provider as ErrorProvider, ErrorBoundary } from '@rollbar/react';
import { FeatureFlagProvider } from './context/FeatureFlagsProvider';
import { AuroraProvider } from './aurora/theme/AuroraProvider';
import { store } from './state/store';
import { Provider as StateProvider } from 'react-redux';

const rollbarConfig =
  process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN &&
  process.env.REACT_APP_ENVIRONMENT
    ? {
        accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
        environment: process.env.REACT_APP_ENVIRONMENT,
      }
    : {
        enabled: false,
      };

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'production') {
  if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
    for (const [key, value] of Object.entries(
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__
    )) {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] =
        typeof value == 'function' ? () => {} : null;
    }
  }
}

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Failed to find the root element');
}

const root = ReactDOM.createRoot(rootElement);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <FeatureFlagProvider>
      <AuroraProvider>
        <StateProvider store={store}>
          <ErrorProvider config={rollbarConfig}>
            <ErrorBoundary>
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
            </ErrorBoundary>
          </ErrorProvider>
        </StateProvider>
      </AuroraProvider>
    </FeatureFlagProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

import React from 'react';
import ModalAction from '../../../components/table/ModalAction';
import { DeviceEntity } from '../../../state/devices/types';
import { IconEdit } from '../../../aurora/icons';
import DeviceForm from './DeviceForm';
import DevicesApi from '../../../api/devicesApi';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { isArtyc, selectAuth } from '../../../state/auth';
import { useSelector } from 'react-redux';
import { LoadDataAction } from '../../../state/types';

interface Props {
  device: DeviceEntity;
  loadDevices: LoadDataAction<DeviceEntity>;
}

const EditDeviceButton = ({ device, loadDevices: loadDataAction }: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);

  if (!isArtyc(auth)) {
    return null;
  }

  return (
    <ModalAction
      label="Edit Shipper"
      modalTitle="Edit Device"
      loadDataAction={loadDataAction}
      stateName="devices"
      icon={IconEdit}
      modalContent={(onDataChange, onClose) => (
        <DeviceForm
          forDevice={device}
          onSubmit={async (data) => {
            await DevicesApi.updateDevice(axiosPrivate, device._id, data);
            await onDataChange();
          }}
          successMessage="Successfully updated device"
          errorMessage="Failed to update device. Please try again or contact support"
          submitText="Save Changes"
          onClose={onClose}
          onDelete={async () => {
            await DevicesApi.deleteDevice(axiosPrivate, device._id);
            await onDataChange();
          }}
        />
      )}
    />
  );
};

export default EditDeviceButton;

import React from 'react';
import { DeviceEntity } from '../../../state/devices/types';
import { LoadDataAction } from '../../../state/types';
import { Tooltip } from '../../../aurora/components/Tooltip/Tooltip';
import DeviceEnvSwitchButton from './DeviceEnvSwitchButton';

interface Props {
  devices: DeviceEntity[];
  loadDevices: LoadDataAction<DeviceEntity>;
}

const DevicesBulkAction = ({ devices, loadDevices }: Props) => {
  const isDisabled = devices.some((device) => device.companyName !== 'Artyc');
  const tooltipLabel = 'Shippers must be associated with Artyc';

  return (
    <>
      {
        <>
          {isDisabled ? (
            <Tooltip label={tooltipLabel}>
              <DeviceEnvSwitchButton
                devices={devices}
                loadDevices={loadDevices}
                isLabeledButton
                isDisabled
              />
            </Tooltip>
          ) : (
            <DeviceEnvSwitchButton
              devices={devices}
              loadDevices={loadDevices}
              isLabeledButton
            />
          )}
        </>
      }
    </>
  );
};

export default DevicesBulkAction;

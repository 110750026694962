import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../aurora/components/Button/Button';
import {
  IconGraph,
  IconDownload,
  IconArrowForward,
  IconMap,
} from '../../../aurora/icons';
import {
  downloadCsvAction,
  ShipmentModalTypeEnum,
} from '../../../state/shipments';
import { AppDispatch } from '../../../state/store';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { isArtyc, selectAuth } from '../../../state/auth';
import { ShipmentEntity } from '../../../state/shipments/types';

interface Props {
  shipments: ShipmentEntity[];
  exportGraphs: (shipments: ShipmentEntity[]) => void;
  openModal: (
    shipments: ShipmentEntity[],
    modalType: ShipmentModalTypeEnum
  ) => void;
}
const ShipmentsBulkAction = ({ shipments, openModal, exportGraphs }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);

  const allowBulkMap = shipments.every((shipment) => shipment.hasGeoData);

  const downloadShipmentCsv = () => {
    dispatch(
      downloadCsvAction({
        axios: axiosPrivate,
        auth,
        shipments,
        fullLog: false,
      })
    );
  };

  return (
    <>
      <Button
        variant="secondary"
        label="Export Graphs"
        iconLeft={IconArrowForward}
        onClick={() => exportGraphs(shipments)}
        sx={{ marginX: '12px' }}
      />

      {isArtyc(auth) ? (
        <Button
          variant="secondary"
          label="Open Graphs"
          iconLeft={IconGraph}
          sx={{ marginX: '12px' }}
          onClick={() => openModal(shipments, ShipmentModalTypeEnum.Graph)}
        />
      ) : null}
      {allowBulkMap && isArtyc(auth) && (
        <Button
          variant="secondary"
          label="Open Maps"
          iconLeft={IconMap}
          sx={{ marginX: '12px' }}
          onClick={() => openModal(shipments, ShipmentModalTypeEnum.Map)}
        />
      )}
      <Button
        variant="secondary"
        label="Download Shipments"
        iconLeft={IconDownload}
        sx={{ marginX: '12px' }}
        onClick={downloadShipmentCsv}
      />
    </>
  );
};

export default ShipmentsBulkAction;

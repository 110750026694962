import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  LoadDataAction,
  EntityState,
  DataPayload,
  SortPayloadAction,
} from '../types';
import { AxiosInstance } from 'axios';
import {
  ShipmentEntity,
  ShipmentFilters,
  ShipmentSnapshotEntity,
} from './types';
import { structuredFetch } from '../../api/api';
import { Auth } from '../auth/types';
import ShipmentsApi from '../../api/shipmentsApi';
import { showToast } from '../../aurora/components/Toast/Toast';
import { RootState } from '../store';

export enum ShipmentModalTypeEnum {
  Map = 'map',
  Graph = 'graph',
}

export type ShipmentToSnapshotsMap = Record<
  ShipmentEntity['_id'],
  ShipmentSnapshotEntity[]
>;

export type ShipmentModalType = ShipmentModalTypeEnum | null;
export interface ShipmentState extends EntityState<ShipmentEntity> {
  selectedFilters?: ShipmentFilters;
  modalShipments: ShipmentEntity[];
  modalType: ShipmentModalType;
  loadedGraphs: number;
  viewAsCustomer: boolean;
  snapshotsByShipment: ShipmentToSnapshotsMap;
}

const initialState: ShipmentState = {
  data: [],
  currentPage: 0,
  resultsPerPage: 8,
  totalPages: 0,
  sortColumn: 'createdAt',
  sortDirection: -1,
  selectedFilters: {},
  modalShipments: [],
  modalType: null,
  loadedGraphs: 0,
  viewAsCustomer: false,
  snapshotsByShipment: {},
};

interface BulkActionPayload {
  axios: AxiosInstance;
  auth: Auth | null;
  shipments: ShipmentEntity[];
}
export const downloadCsvAction = createAsyncThunk(
  'shipments/downloadCsv',
  ({
    axios,
    auth,
    shipments,
    fullLog,
  }: BulkActionPayload & { fullLog: boolean }) => {
    const shipmentIds = shipments.map((shipment) => shipment._id);
    if (fullLog) {
      return ShipmentsApi.downloadLogCsv(axios, auth, shipmentIds);
    }
    return ShipmentsApi.downloadShipmentCsv(axios, auth, shipmentIds);
  }
);

const shipmentSlice = createSlice({
  name: 'shipments',
  initialState,
  reducers: {
    setShipments: (
      state,
      { payload }: PayloadAction<DataPayload<ShipmentEntity>>
    ) => {
      state.data = payload.entities;
      state.totalPages = payload.totalPages;
    },
    setSnapshots: (state, action: PayloadAction<ShipmentToSnapshotsMap>) => {
      state.snapshotsByShipment = {
        ...state.snapshotsByShipment,
        ...action.payload,
      };
    },
    sortShipments: (state, { payload }: SortPayloadAction<ShipmentEntity>) => {
      state.sortColumn = payload.column;
      state.sortDirection = payload.direction;
    },
    setDeviceTypesFilter: (
      state,
      { payload }: PayloadAction<string[] | undefined>
    ) => {
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        deviceTypes: payload,
      });
      state.currentPage = initialState.currentPage;
    },
    setDateFilter: (
      state,
      { payload }: PayloadAction<[string | undefined, string | undefined]>
    ) => {
      const [startDate, endDate] = payload;
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        startDate,
        endDate,
      });
      state.currentPage = initialState.currentPage;
    },
    setSearchFilter: (state, { payload }: PayloadAction<string>) => {
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        searchQuery: payload === '' ? undefined : payload,
      });
      state.currentPage = initialState.currentPage;
    },
    setCompanyIdsFilter: (
      state,
      { payload }: PayloadAction<string[] | undefined>
    ) => {
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        companyIds: payload,
      });
      state.currentPage = initialState.currentPage;
    },
    setCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.currentPage = payload;
    },
    resetPage: () => initialState,
    setModalShipments: (
      state,
      { payload }: PayloadAction<ShipmentEntity[]>
    ) => {
      state.modalShipments = payload;
    },
    setModalType: (state, { payload }: PayloadAction<ShipmentModalType>) => {
      state.modalType = payload;
    },
    incrementLoadedGraph: (state) => {
      state.loadedGraphs = state.loadedGraphs + 1;
    },
    resetLoadedGraph: (state) => {
      state.loadedGraphs = 0;
    },
    toggleViewAsCustomer: (state) => {
      state.viewAsCustomer = !state.viewAsCustomer;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadCsvAction.pending, () => {
      showToast({
        type: 'loading',
        title: 'Downloading',
        text: 'Preparing your files for download. Please wait...',
      });
    });

    builder.addCase(downloadCsvAction.fulfilled, (state, action) => {
      const url = window.URL.createObjectURL(new Blob([action.payload]));

      const link = document.createElement('a');
      link.href = url;
      if (action.payload.type === 'text/csv') {
        const shipment = action.meta.arg.shipments[0];
        const logType = action.meta.arg.fullLog ? 'full_log' : 'shipment';

        link.setAttribute(
          'download',
          `${shipment.serialNumber}-${logType}-${shipment.pid}.csv`
        );
      } else {
        link.setAttribute('download', 'shipments.zip');
      }

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      showToast({
        type: 'success',
        title: 'Success!',
        text: 'Your files have successfully downloaded.\nPlease check your downloads folder.',
      });
    });

    builder.addCase(downloadCsvAction.rejected, () => {
      showToast({
        type: 'error',
        title: 'Uh oh...',
        text: 'There was an error preparing your files. \nPlease try again or contact support if it continues.',
      });
    });
  },
});

export const selectSnapshotsByShipment = (state: RootState) =>
  state.shipments.snapshotsByShipment;

export const loadShipments: LoadDataAction<ShipmentEntity> = async (
  axios: AxiosInstance,
  auth: Auth,
  state: EntityState<ShipmentEntity>
) => {
  const {
    resultsPerPage,
    currentPage,
    sortColumn,
    sortDirection,
    selectedFilters,
  } = state;

  const { entities, totalPages } = await structuredFetch<ShipmentEntity>(
    axios,
    auth,
    'shipments',
    resultsPerPage,
    currentPage,
    sortColumn,
    sortDirection,
    selectedFilters
  );

  return setShipments({ entities, totalPages });
};

export const {
  setShipments,
  setSnapshots,
  sortShipments,
  setDeviceTypesFilter,
  setSearchFilter,
  setCompanyIdsFilter,
  setDateFilter,
  setCurrentPage,
  resetPage,
  setModalShipments,
  setModalType,
  incrementLoadedGraph,
  resetLoadedGraph,
  toggleViewAsCustomer,
} = shipmentSlice.actions;

export default shipmentSlice.reducer;

import React, { PropsWithChildren } from 'react';
import styles from './DataModal.module.scss';
import { IconClose } from '../aurora/icons';
import { Text } from '../aurora/typography/Text/Text';
import ReactDOM from 'react-dom';

type OnCloseFunction = () => void;
interface Props {
  title?: string;
  show: boolean;
  onClose: OnCloseFunction;
  width?: string;
  showCloseIcon?: boolean;
}

const DataModal = ({
  title,
  show,
  onClose,
  children,
  width,
  showCloseIcon = true,
}: PropsWithChildren<Props>) => {
  const root = document.getElementById('modalContainer');

  if (root === null) {
    return null;
  }

  if (!show) {
    return null;
  }

  const shouldShowHeader = title || showCloseIcon;

  return ReactDOM.createPortal(
    <div className={styles.modalContainer}>
      <div className={styles.modal} style={{ width: width || '558px' }}>
        {shouldShowHeader && (
          <div className={styles.modalHeader}>
            <div className={styles.spacer}></div>
            {title && (
              <Text size="l" sx={{ fontWeight: '600', color: 'gray700' }}>
                {title}
              </Text>
            )}
            {showCloseIcon && (
              <div onClick={onClose}>
                <IconClose width={22} height={22} color="gray950" />
              </div>
            )}
          </div>
        )}
        <div className={styles.modalContent}>{children}</div>
      </div>
    </div>,
    root
  );
};

export default DataModal;

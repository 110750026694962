import React from 'react';
import styles from './StatusBadge.module.scss';
import { IconDot } from '../../icons';

interface Props {
  content: string;
  success: boolean;
}
const StatusBadge = ({ content, success: status }: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={status ? styles.success : styles.faded}>
          <IconDot width={16} height={16} />
        </div>
        <span>{content}</span>
      </div>
    </div>
  );
};

export default StatusBadge;

import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { IconClose } from '../../icons';
import { Button } from '../Button/Button';
import {
  closeStyle,
  dropzoneStyle,
  imgContainerStyle,
  imgStyle,
  thumbStyle,
  thumbsContainerStyle,
} from './fileUploadStyles';
import { useSx } from 'dripsy';
import { Text } from '../../typography/Text/Text';

export type FileWithPreview = File & { preview: string };

interface Props {
  onSetFiles?: (files: FileWithPreview[]) => void;
  fileType?: string;
  maxFiles?: number;
  label?: string;
}
const FileUpload = ({ onSetFiles, fileType, maxFiles, label }: Props) => {
  const sx = useSx();

  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const handleSetFiles = (toSet: FileWithPreview[]) => {
    setFiles(toSet);
    onSetFiles && onSetFiles(toSet);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      [fileType || 'image/*']: [],
    },
    maxFiles,
    multiple: maxFiles !== 1,
    onDrop: (acceptedFiles) => {
      handleSetFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div style={sx(thumbStyle)} key={file.name}>
      <Button
        iconLeft={IconClose}
        variant="ghostSecondary"
        onClick={() =>
          handleSetFiles(files.filter((f) => f.name !== file.name))
        }
        sx={closeStyle}
      />
      <div style={imgContainerStyle}>
        {file.type.includes('image') ? (
          <img
            src={file.preview}
            style={imgStyle}
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
          />
        ) : (
          <div>{file.name}</div>
        )}
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  const id = label ? label.toLowerCase().replace(/[^a-z0-g]/g, '-') : undefined;

  return (
    <div>
      {label && (
        <label
          htmlFor={id}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            paddingBottom: '6px',
          }}
        >
          <Text
            size="s"
            sx={{
              color: 'gray600',
              fontWeight: '500',
              letterSpacing: '-0.1px',
              lineHeight: '20px',
            }}
          >
            {label}
          </Text>
        </label>
      )}
      <section style={sx(dropzoneStyle)}>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <Text size="s" sx={{ color: 'gray600' }}>
            Drag a file here, or click to select
          </Text>
        </div>
        <aside style={thumbsContainerStyle}>{thumbs}</aside>
      </section>
    </div>
  );
};

export default FileUpload;

// for the first few, return a reasonable color option. after that, give a random color
export const genColorHex = (seed: number): string => {
  switch (seed) {
    case 0:
      return '#0ef7d0';
    case 1:
      return '#ea3b73';
    case 2:
      return '#fe827b';
    default:
      return `#${Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, '0')}`;
  }
};

// given a hex, return one that's similar but slightly different
export const genAdjustedColorHex = (hex: string) => {
  const amount = 20;

  // Remove the '#' if it is present
  let color = hex.replace('#', '');

  // Convert the hex color to an RGB object
  let r = parseInt(color.substring(0, 2), 16);
  let g = parseInt(color.substring(2, 4), 16);
  let b = parseInt(color.substring(4, 6), 16);

  // Adjust each color component by a random amount within the specified range
  r = Math.min(
    255,
    Math.max(0, r + Math.floor(Math.random() * (amount * 2 + 1)) - amount)
  );
  g = Math.min(
    255,
    Math.max(0, g + Math.floor(Math.random() * (amount * 2 + 1)) - amount)
  );
  b = Math.min(
    255,
    Math.max(0, b + Math.floor(Math.random() * (amount * 2 + 1)) - amount)
  );

  // Convert the RGB values back to a hex string
  const newHex = `#${r.toString(16).padStart(2, '0')}${g
    .toString(16)
    .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;

  return newHex;
};

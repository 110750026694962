import React from 'react';
import {
  ShipmentEntity,
  ShipmentSnapshotEntity,
} from '../../state/shipments/types';
import { Text } from '../../aurora/typography/Text/Text';
import { useSelector } from 'react-redux';
import { isArtyc, selectAuth } from '../../state/auth';
import { formatDate, secondsToDuration } from '../../utils/dateUtil';
import styles from './ShipmentInfoCard.module.scss';

interface Tag {
  label: string;
  value: string | number;
  artycOnly?: boolean;
}

interface Props {
  shipment: ShipmentEntity | undefined;
  snapshots: ShipmentSnapshotEntity[];
}
const ShipmentInfoCard = ({ shipment, snapshots }: Props) => {
  if (!shipment) {
    return null;
  }

  const auth = useSelector(selectAuth);

  const tags: Tag[] = [
    { label: 'Shipment ID', value: shipment.pid },
    { label: 'Company', value: shipment.companyName, artycOnly: true },
    { label: 'Internal ID', value: shipment._id, artycOnly: true },
    { label: 'Start Date', value: formatDate(shipment.startTime) },
    { label: 'End Date', value: formatDate(shipment.endTime) },
    { label: 'Duration', value: secondsToDuration(shipment.lengthSec) },
    {
      label: 'Firmware Version',
      value: shipment.firmwareVersion,
      artycOnly: true,
    },
    { label: 'Snapshots', value: snapshots.length, artycOnly: true },
    {
      label: 'Remaining Battery',
      value:
        snapshots.length > 0 ? `${snapshots[snapshots.length - 1].soc}%` : '-',
      artycOnly: true,
    },
  ];

  return (
    <div className={styles.infoCard}>
      <div className={styles.infoCardHeader}>
        <Text size="l" className={styles.headingText}>
          {shipment.deviceType}
        </Text>
        <Text size="s" className={styles.subheadingText}>
          Serial #{shipment.serialNumber}
        </Text>
      </div>
      <div className={styles.infoCardBody}>
        {tags
          .filter((tag) => !tag.artycOnly || (tag.artycOnly && isArtyc(auth)))
          .map((tag, idx) => (
            <div key={`tag-${idx}`}>
              <Text size="s" className={styles.subheadingText}>
                {tag.label}
              </Text>
              <Text size="s" className={styles.bodyText}>
                {tag.value}
              </Text>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ShipmentInfoCard;
